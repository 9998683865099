<template>
  <div class="section">
    <div class="filter-box">
      <ul class="filter-ul">
        <select-picker :data="filterData.trackType" />
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.sellUserId"
          @toTree="toTree"
        />
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.orgId"
          @toTree="toTree"
        />
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.branchOrgId"
        />
        <!-- <select-picker :data="filterData.areaOrgId"></select-picker> -->
        <select-picker :data="filterData.isAccompany" />
        <select-picker
          v-if="filterData.isAccompany.value == 1"
          :data="filterData.accompanyId"
          @toTree="toTree"
        />
        <select-picker :data="filterData.effective" />
        <select-picker :data="filterData.trackReliable" />
      </ul>
    </div>
    <div class="bottom-postion">
      <cube-button
        :outline="true"
        class="btn btns"
        @click="reset"
      >
        重置
      </cube-button>
      <cube-button
        class="btn btns"
        @click="confirm"
      >
        确认
      </cube-button>
    </div>
  </div>
</template>

<script>
import selectPicker from '_c/picker/select';
import { selectOrgByOrgType } from '_api/global';
import { regular } from '@/lib/until';
import { mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    selectPicker
  },
  data() {
    return {
      filterData: {
        trackType: {
          title: '跟进类型',
          name: '',
          value: '',
          options: []
        },
        sellUserId: {
          title: '销售',
          name: '',
          value: '',
          dap: false,
          typeName: 'sellUserId'
        },
        orgId: {
          title: '所属部门',
          name: '',
          value: '',
          dap: false,
          typeName: 'orgId'
        },
        branchOrgId: {
          title: '分公司',
          name: '',
          value: '',
          options: []
        },
        // areaOrgId: {
        //   title: '大区',
        //   name: '',
        //   value: '',
        //   options: []
        // },
        isAccompany: {
          title: '是否陪访人员',
          name: '',
          value: '',
          options: [{ text: '是', value: 1 }, { text: '否', value: 0 }]
        },
        accompanyId: {
          title: '陪访人员',
          name: '',
          value: '',
          dap: false,
          typeName: 'accompanyId'
        },
        effective: {
          title: '是否有效',
          name: '',
          value: '',
          options: [{ text: '是', value: 1 }, { text: '否', value: 0 }]
        },
        trackReliable: {
          title: '进程推进',
          name: '',
          value: '',
          options: []
        }
      }
    };
  },
  computed: {
    ...mapGetters('follow', ['filterObj']),
    ...mapGetters(['dictList'])
  },
  mounted() {
    this.getOptions();
    // 返回条件
    this.getSellData();
    // 获取分公司
    this.getRegionFiliales(2);
    // 获取大区
    // this.getRegionFiliales(3);
  },
  methods: {
    ...mapMutations('follow', ['FOLLOW_FILTER']),
    ...mapMutations(['CLEAR_FILTER']),
    confirm() {
      this.cache();
      this.$router.go(-1);
    },
    reset() {
      for (const i in this.filterData) {
        this.filterData[i].name = '';
        this.filterData[i].value = '';
      }
      this.CLEAR_FILTER('follow');
    },
    cache() {
      const filterData = {};
      for (const i in this.filterData) {
        filterData[i] = {
          name: this.filterData[i].name,
          value: this.filterData[i].value
        };
      }

      this.FOLLOW_FILTER(filterData);
    },
    toTree(typeName) {
      this.cache();
      this.$router.push(
        {
          name: 'tree',
          params: {
            routerName: this.$route.name,
            typeName
          }
        },
      );
    },
    getSellData() {
      if (JSON.stringify(this.filterObj) !== '{}') {
        for (const i in this.filterObj) {
          this.filterData[i].name = this.filterObj[i] ? this.filterObj[i].name : '';
          this.filterData[i].value = this.filterObj[i] ? this.filterObj[i].value : '';
        }
      }
    },
    getRegionFiliales(orgType) {
      selectOrgByOrgType(orgType).then(res => {
        if (res.flag) {
          let list = res.data;
          list.forEach(item => {
            if (orgType === 2) {
              this.filterData.branchOrgId.options.push(
                {
                  text: regular(item.key),
                  value: item.id
                }
              );
            } else {
              this.filterData.areaOrgId.options.push(
                {
                  text: regular(item.key),
                  value: item.id
                }
              );
            }
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getOptions() {
      this.filterData.trackType.options = this.dictList.trackType;
      this.filterData.trackReliable.options = this.dictList.reliable;
    }
  }
};

</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
